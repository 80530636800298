import axios from "axios";
import store from "@/store";
import router from "@/router";
let qs = require("qs"); //
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// 导出baseURL 因为其他地方要用  有些地方不用
// https://apipc-xiaotuxian-front.itheima.net/
export const baseURL = "http://175.178.184.119:3009/";

const _axios = axios.create({
  baseURL,
  timeout: 5000,
});

_axios.interceptors.request.use(
  (config) => {
    // 要在头部携带token
    const { profile } = store.state.user; // 获取用户信息对象
    // 判断有无token
    if (profile.token) {
      config.headers.Authorization = `Bearer ${profile.token}`;
    }
    // 添加 Upgrade-Insecure-Requests 请求头，设置为 0，表示不需要自动升级为https
    // config.headers["Upgrade-Insecure-Requests"] = "0";
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

_axios.interceptors.response.use(
  (res) => {
    console.log(res);
    return res.data;
  },
  (err) => {
    // 401状态码 [token失效或没有传递,就会报401错误]  进入↓ 有些没有err.response 所以要判断
    if (err.response && err.response.status === 401) {
      // 清空无效信息
      store.commit("user/setUser", {});
      // 跳转到登录页码
      // 跳转需要传参router给登录页
      // 组件中  /user?a=666  $route.path获取的是/user 要用fullPath获取完整的
      // js模块中 router.currentRoute.value.fullPath就是当前路由 router.currentRoute是ref响应式的得加value

      // 如果/user?a=666&b=100 有特殊字符? & 要转码再放到路由地址中 !!!
      // const fullPath = encodeURIComponent(router.currentRoute.value.fullPath);
      console.log(fullPath);
      //没有登录 要拿到submitData参数并且 跳转到登录页面
      router.push("/login?redirectUrl=" + `/`);
    }
    return Promise.reject(err);
  }
);

// const request = ()=>{

// }
export default (url, method, submitData) => {
  // return 的是一个Promise
  // const a={}
  // if()
  return _axios({
    url,
    method,
    // get请求 要用params传递submitData
    // 不是get请求 用data传递submitData  因为有两种情况 所以用动态键名(贱民)
    // const a = { name:www,age:100} 可以用a[100>50?'name':'age'] 可以写表达式 满足条件就取a.name 不满足取a.age
    // 也可以写 const kk = 'name' 然后a[kk]
    // get可能是GET Get 所以要转换成小写
    [method.toLowerCase() === "get" ? "params" : "data"]: submitData,
  });
};
