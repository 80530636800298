

import request from "../utils/request";
let qs = require('qs')//

//获取账号信息
export const getLOLAccount = ()=>{
    return request(`/api/getLOLAccount`,'get')
}
//获取真正密码realPassword
export const getRealPassword = ()=>{
    return request(`/my/getRealPassword`,'get')
}
//修改lol账号信息
export const updateLOLAccount = ({id,area,myAccount,password,realPassword,myID,nowRank,lastRank,hero,connectID})=>{
    return request(`/my/updateLOLAccount`,'post',qs.stringify({id,area,myAccount,password,realPassword,myID,nowRank,lastRank,hero,connectID}))
}

//获取这个id的所有图片  get请求是用req.query获取参数!!!!!
export const getAccImg = (id)=>{

    return request(`/my/getAccImg`,'get',{id})
}

// export const uploadAccPic = ()=>{
//     return request(`/my/uploadAccPic`)
// }

export const deleAccImg = ({id,urls})=>{
    return request(`/my/deleAccImg`,'post',qs.stringify({id,urls}))
}









