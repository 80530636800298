import { createRouter, createWebHashHistory } from "vue-router";
import LayOut from "../views/LayOut.vue";
import Hao from "../views/home/Hao.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: LayOut,
    children: [{ path: "/", component:Hao  }],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
