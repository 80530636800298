// import {getNewCartGoods ,mergeCart ,getGoodsSku,findCart,insertCart,
//   deleteCart,updateCart,checkAllCart} from "@/api/cart";
export default {
  namespaced: true,
  state() {
    return {
      // 用户信息
      profile: {
        id: '', //用户id 也是用于查询的connectID
        user_pic: '',
        nickname: '',
        username: '',
        email: '',
        token: ''
      },
    };
  },
  getters: {
    // isCheckAll(state,getters){
    //   /* 是否全选 选择的总数=有效商品长度 并且有效商品长度 不=0 */
    //   return ( getters.selectedList.length === getters.validList.length ) && getters.validList.length !== 0
    // }
  },
  mutations: {
      // 修改用户信息
      setUser(state, payload) {
        state.profile = payload;
        console.log('payload',payload);
      },
  },
  actions: {
    
          
    
    
  }
};
