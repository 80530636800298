import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

// 三个模块
import user from "./modules/user";


export default createStore({
  modules: {
    user
  },
  // 配置插件
  plugins: [
    // 默认存储在localStorage
    createPersistedState({
      // 本地存储名字
      key: "erabbit-client-pc-124-store",
      // 指定需要持久缓存的模块
      paths: ["user"]
    })
  ]
});
