import request from "../utils/request";
let qs = require('qs')//

//注册
export const registerUser = (username,password)=>{
  console.log(username);
    return request(`/api/register`,'post',qs.stringify({username,password}))
}
//登录
export const login = (username,password)=>{
  console.log(username);
    return request(`/api/login`,'post',qs.stringify({username,password}))
}
