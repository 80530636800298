"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = {
    class: "demo-pagination-block",
    style: { "margin-top": "15px" }
};
var vue_3 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'pagination-lol',
    props: { totalAccount: Number },
    emits: ['changePageSize', 'changeCurPage'],
    setup: function (__props, _a) {
        var emit = _a.emit;
        var props = __props;
        var currentPage4 = (0, vue_3.ref)(1);
        var pageSize4 = (0, vue_3.ref)(15);
        var disabled = (0, vue_3.ref)(false);
        var handleSizeChange = function (val) {
            console.log("".concat(val, " items per page"));
            emit('changePageSize', val);
            pageSize4.value = val;
        };
        var handleCurrentChange = function (val) {
            console.log("current page: ".concat(val));
            emit('changeCurPage', val);
        };
        return function (_ctx, _cache) {
            var _component_el_pagination = (0, vue_2.resolveComponent)("el-pagination");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createVNode)(_component_el_pagination, {
                    currentPage: currentPage4.value,
                    "onUpdate:currentPage": _cache[0] || (_cache[0] = function ($event) { return ((currentPage4).value = $event); }),
                    "page-size": pageSize4.value,
                    "onUpdate:page-size": _cache[1] || (_cache[1] = function ($event) { return ((pageSize4).value = $event); }),
                    "page-sizes": [15, 20, 25, 30, 35, 40],
                    disabled: disabled.value,
                    background: "",
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: __props.totalAccount,
                    onSizeChange: handleSizeChange,
                    onCurrentChange: handleCurrentChange
                }, null, 8, ["currentPage", "page-size", "disabled", "total"])
            ]));
        };
    }
});
