"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "home" };
var _hoisted_2 = { class: "app-body" };
var top_nav_bar_vue_1 = require("@/components/top/top-nav-bar.vue");
var foot_one_vue_1 = require("@/components/top/foot-one.vue");
var element_plus_1 = require("element-plus");
var vue_3 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'LayOut',
    setup: function (__props) {
        (0, vue_3.onMounted)(function () {
            open();
        });
        var open = function () {
            element_plus_1.ElMessageBox.alert('为了更好地cf队友，希望各位能够不定时更新账号信息(例如:上传截图/更改段位),不要用lj换肤,换肤请使用[美化伴侣]!', '公告', {
                confirmButtonText: '好的一定',
            });
        };
        return function (_ctx, _cache) {
            var _component_RouterView = (0, vue_2.resolveComponent)("RouterView");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createVNode)(top_nav_bar_vue_1.default),
                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                    (0, vue_2.createVNode)(_component_RouterView)
                ]),
                (0, vue_2.createVNode)(foot_one_vue_1.default)
            ]));
        };
    }
});
